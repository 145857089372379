import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import loadUser from '../utils/loadUser';
import store from '../store';
import userManager from '../utils/userManager';
import { load } from './action-creators';
import { state as asyncState } from '../utils/fetch';
import AuthenticationContext from './AuthenticationContext';

class RequireUserProvider extends React.Component {
  static propTypes = {
    authentication: PropTypes.object,
    location: PropTypes.object,
    appInsights: PropTypes.object,
    user: PropTypes.any,
    authLoad: PropTypes.func,
  }
  componentDidMount() {
    const { authentication, location, authLoad, appInsights} = this.props;
    const localPath = location.pathname;
    loadUser(store, userManager).then(user => {
      if (!user || user.expired) {
        userManager.signinRedirect({ state: { localPath } });
      } else if (authentication.state === asyncState.notTriggered) {
        authLoad();
        appInsights.setAuthenticatedUserContext(user.profile.oid.replace(/[,;=| ]+/g, '_') , '' , true);
      }
    });
  }

  render() {
    const { authentication, user } = this.props;
    const authenticatedUser = authentication.state === asyncState.done && user;
    return (
      <AuthenticationContext.Provider value={authenticatedUser}>
        {this.props.children({ user: authenticatedUser })}
      </AuthenticationContext.Provider>
    );
  }
}

// TODO: Merge user and authentication
const mapStateToProps = state => ({
  user: state.oidc.user,
  authentication: state.authentication,
});
const mapDispatchToProps = {
  authLoad: load
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireUserProvider);
