import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as StyletronClient } from 'styletron-engine-monolithic';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import store from './store';
import userManager from './utils/userManager';
import RequireUserProvider from './authentication/RequireUserProvider';

import Shipment from './features/shipment';
import ServiceUsers from './features/serviceUsers';
import Locations from './features/locations';
import Contracts from './features/contracts';
import AppConfigPage from './features/appConfig';
import Statistics from './features/Statistics';
import Invoicing from './features/invoicing';
import { Container } from './components/Layout';
import Wrapper from './components/Wrapper';
import GraphiQL from './components/GraphiQL';

import { ViewportProvider } from './components/Viewport';
import { Provider as UserDataProvider } from './components/UserData';

import * as Oidc from './components/Oidc';
import ErrorBoundary from './components/ErrorBoundary';

import * as serviceWorker from './serviceWorker';
import serviceWorkerConfig from './serviceWorkerConfig';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

// Add new Styletron Engine
const engine = new StyletronClient();
// var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS,
    // extensions: [reactPlugin]
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory }
    // }
  },
});
console.log('Welome to smartflow!');

appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.context.application.ver = process.env.REACT_APP_VERSION;

// Set Promise polyfill
if (!window.Promise) window.Promise = Promise;

const App = () => (
  <StyletronProvider value={engine}>
    <ReduxProvider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ErrorBoundary>
          <ViewportProvider>
            <UserDataProvider>
              <BrowserRouter>
                <Switch>
                  <Route path="/callback" component={Oidc.Callback} />
                  <Route path="/silentrenew" component={Oidc.SilentRenew} />
                  <Route path="/signout-callback-oidc" component={Oidc.Logout} />
                  <Route
                    path="/graphql"
                    render={({ location }) => (
                      <RequireUserProvider location={location}>
                        {({ user }) => user && <GraphiQL token={user.access_token} />}
                      </RequireUserProvider>
                    )}
                  />
                  <Route
                    path="/"
                    render={({ location }) => (
                      <RequireUserProvider location={location} appInsights= {appInsights}>
                        {({ user }) => (
                          <Wrapper user={user} location={location} appInsights= {appInsights}>
                            <Container>
                              <Switch>
                                <Route path="/shipments" component={Shipment} />
                                <Route path="/recipients" component={ServiceUsers} />
                                <Route path="/locations" component={Locations} />
                                <Route path="/customers" component={Contracts} />
                                <Route path="/statistics" component={Statistics} />
                                <Route path="/config" component={AppConfigPage} />
                                <Route path="/invoicing" component={Invoicing} />
                                <Redirect to="/shipments" />
                              </Switch>
                            </Container>
                          </Wrapper>
                        )}
                      </RequireUserProvider>
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </UserDataProvider>
          </ViewportProvider>
        </ErrorBoundary>
      </OidcProvider>
    </ReduxProvider>
  </StyletronProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
serviceWorker.register(serviceWorkerConfig);
