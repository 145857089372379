import React, { useEffect, useState } from 'react';
import { styled, withStyle } from 'styletron-react';
import style from '../../../style';
import Form from './Form';
import { Row, Col, NoOffsetCol, Well } from '../../../components/Layout';
import Preview from './Preview';
import { useAuthenticatedFetch } from '../../../utils/fetch';
import { htmlToMarkdown } from '../../../utils/htmlToMarkdown';
import { removeLeadingSpaces } from '../../../utils/removeLeadingSpaces';

const Name = styled('i', {
  display: 'inline-block',
});

const FlexCol = withStyle(Col, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const NoOffsetFlexCol = withStyle(FlexCol, {
  padding: '20px',
});

const SnugRow = withStyle(Row, {
  marginLeft: '0',
  marginRight: '0',
});
const SnugCol = withStyle(Col, {
  paddingRight: '0',
  paddingLeft: '0',
});

const Property = styled('code', {
  background: style.superLight,
  padding: '0.25em',
});

const Snippet = styled('pre', {
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  background: style.superLight,
  padding: '0.5rem',
});

const useDebounce = value => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  });
  return debouncedValue;
};

// The logic here is:
// 1: Load existing mailtemplate.
// 2: "validate" the existing mailtemplate, actually, fetch a compiled version.
// 3: Debounce a validation/compilation of the edited template when the user types.
export default ({ contract }) => {
  let [{ data: existingMailTemplate, isLoading: isMailTemplateLoading }, fetchMailTemplate] = useAuthenticatedFetch(
    `api/contract/${contract.id}/mailtemplate`,
    undefined
  );

  const [debouncedTemplate, setDebouncedTemplate] = useState();
  const d = useDebounce(debouncedTemplate);

  const [compiled, setCompiled] = useState('');
  const [existingMailTemplateTrimmed, setExistingMailTemplateTrimmed] = useState(null);
  const [
    { data: compiledTemplate, isLoading: isValidating, errors: validationErrors },
    validateTemplate,
  ] = useAuthenticatedFetch(`api/contract/${contract.id}/mailtemplate/validate`, {
    method: 'POST',
    body: JSON.stringify(d || existingMailTemplateTrimmed),
  });

  if (!existingMailTemplateTrimmed && !isMailTemplateLoading) {
    fetchMailTemplate();
  }

  if (existingMailTemplateTrimmed && !isValidating && !compiled) {
    validateTemplate();
  }

  useEffect(() => {
    if(existingMailTemplate){
      // Trim the template to be shown correctly as Markdown as sent email.
      const trimmed = {...existingMailTemplate, body: removeLeadingSpaces(existingMailTemplate.body)};
      setExistingMailTemplateTrimmed(trimmed)

    }
  },[existingMailTemplate])


  useEffect(
    () => {
      if (existingMailTemplateTrimmed && compiled) {
        validateTemplate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [d]
  );

  // eslint-disable-next-line no-unused-vars
  const [_, saveMailTemplate] = useAuthenticatedFetch(`api/contract/${contract.id}/mailtemplate`, {
    method: 'PUT',
    body: JSON.stringify(debouncedTemplate || compiledTemplate),
  });

  const mappedValidationErrors =
    validationErrors &&
    validationErrors.data &&
    validationErrors.data
      .filter(item => item[0].key !== 'id')
      .reduce((err, field) => {
        err[field[0].key.toLowerCase()] = field[0].value.errors.reduce((message, err) => {
          return err.errorMessage;
        }, {});
        return err;
      }, {});

  useEffect(
    () => {
      if (compiledTemplate) {
        compiledTemplate.body = htmlToMarkdown(compiledTemplate.body);
        setCompiled(compiledTemplate);
      }
    },
    [compiledTemplate]
  );

  return (
    <div>
      <NoOffsetCol>
        <Row>
          <Col>
            <h2>
              Edit template: <Name>Email template</Name>
            </h2>
            <i>
              Edit the Handlebars template and check the preview in the right column. When you are satisfied click save.
            </i>
          </Col>
        </Row>
      </NoOffsetCol>
      <SnugRow>
        <SnugCol>
          <Well>
            <Form
              renderPreview={e =>
                setDebouncedTemplate({ ...existingMailTemplateTrimmed, ...debouncedTemplate, [e.target.name]: e.target.value })
              }
              mailTemplate={debouncedTemplate || existingMailTemplateTrimmed}
              save={values => saveMailTemplate(values)}
              hasErrors={mappedValidationErrors !== undefined}
            />
          </Well>
        </SnugCol>
        <SnugCol>
          <NoOffsetFlexCol>
            {compiled && <Preview compiledTemplate={compiled} templateErrors={mappedValidationErrors} />}
          </NoOffsetFlexCol>
        </SnugCol>
      </SnugRow>
      <NoOffsetCol>
        <Row>
          <Col>
            <h3>Help</h3>
            <i>
              Handlebars documentation{' '}
              <a href="https://handlebarsjs.com/" target="_BLANK" rel="noopener noreferrer">
                https://handlebarsjs.com/
              </a>
            </i>
          </Col>
        </Row>
      </NoOffsetCol>
      <SnugRow>
        <SnugCol>
          <Well>
            <h4>You may utilize Markdown within your template</h4>
            <i>
              Markdown basic syntax{' '}
              <a href="https://www.markdownguide.org/basic-syntax/" target="_BLANK" rel="noopener noreferrer">
                https://www.markdownguide.org/basic-syntax/
              </a>
            </i>
            <br />
            <br />
            <h5>The following properties are available</h5>
            <ul>
              <li>
                <Property>EMAIL</Property>
              </li>
              <li>
                <Property>FIRSTNAME</Property>
              </li>
              <li>
                <Property>LASTNAME</Property>
              </li>
              <li>
                <Property>PINCODE</Property>
              </li>
              <li>
                <Property>CONSIGNMENTSCOUNT</Property>
              </li>
              <li>
                <Property>ISREMINDER</Property>
              </li>

              <li>
                <Property>HASMULTIPLECONSIGNMENTS</Property>
              </li>
              <li>
                <Property>HASASSIGNEE</Property>
              </li>
              <li>
                <Property>ASSIGNEEFIRSTNAME</Property>
              </li>
              <li>
                <Property>ASSIGNEELASTNAME</Property>
              </li>
              {contract.serviceUserFields
                .map(custom => custom.name)
                .map(key => (
                  <li key={`receiver-${key}`}>
                    <Property>RECEIVER.CUSTOMFIELDS.{key.toUpperCase()}</Property>
                  </li>
                ))}
              {contract.serviceUserFields
                .map(custom => custom.name)
                .map(key => (
                  <li key={`assignee-${key}`}>
                    <Property>ASSIGNEE.CUSTOMFIELDS.{key.toUpperCase()}</Property>
                  </li>
                ))}
              <li>
                <Property>LOCATIONS</Property>
                <ul>
                  <li>
                    <Property>LOCATIONS.COUNT</Property>
                  </li>
                  <li>
                    <Property>NAME</Property>
                  </li>
                  <li>
                    <Property>LETTERS</Property>
                    <ul>
                      <li>
                        <Property>LETTERS.COUNT</Property>
                      </li>
                      <li>
                        <Property>CONSIGNMENTNUMBER</Property>
                      </li>
                      <li>
                        <Property>ISASSIGNEE</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.FIRSTNAME</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.LASTNAME</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.LASTNAME</Property>
                      </li>
                      {contract.consignmentFields
                        .map(custom => custom.name)
                        .map(key => (
                          <li key={`customfield-${key}`}>
                            <Property>CUSTOMFIELDS.{key.toUpperCase()}</Property>
                          </li>
                        ))}
                      {contract.letterFields
                        .map(custom => custom.name)
                        .map(key => (
                          <li key={`letterfields-${key}`}>
                            <Property>CUSTOMFIELDS.{key.toUpperCase()}</Property>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li>
                    <Property>PARCELS</Property>
                    <ul>
                      {['PARCELS.COUNT', 'CONSIGNMENTNUMBER', 'numberOfPackages', 'forwarder', 'referenceNumber'].map(
                        key => (
                          <li key={key}>
                            <Property>{key.toUpperCase()}</Property>
                          </li>
                        )
                      )}
                      <li>
                        <Property>ISASSIGNEE</Property>
                      </li>
                      <li>
                        <Property>DEVIATION</Property>
                      </li>
                      <li>
                        <Property>DEVIATIONNOTE</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.FIRSTNAME</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.LASTNAME</Property>
                      </li>
                      <li>
                        <Property>ORIGINALRECEIVER.LASTNAME</Property>
                      </li>
                      {contract.consignmentFields
                        .map(custom => custom.name)
                        .map(key => (
                          <li key={`customfield-${key}`}>
                            <Property>CUSTOMFIELDS.{key.toUpperCase()}</Property>
                          </li>
                        ))}
                      {contract.parcelFields
                        .map(custom => custom.name)
                        .map(key => (
                          <li key={`parcelfields-${key}`}>
                            <Property>CUSTOMFIELDS.{key.toUpperCase()}</Property>
                          </li>
                        ))}
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </Well>
        </SnugCol>
        <SnugCol>
          <NoOffsetFlexCol>
            <h4>Snippets</h4>
            <i>You may copy paste these into the editor to get the desired output.</i>
            <h5>Subject</h5>
            <Snippet>
              {
                '{{#if IsReminder}}Reminder: {{/if}}You have {{#if HasMultipleConsignments}} shipments {{else}} a shipment {{/if}} to pick up'
              }
            </Snippet>
            <h5>Greeting</h5>
            <Snippet>
              {`{{#if HASASSIGNEE}}
  ### Hi {{FIRSTNAME}}, your shipment will be picked up by {{ASSIGNEEFIRSTNAME}} {{ASSIGNEELASTNAME}}.
{{else}}
  ### Hi {{FIRSTNAME}}, you have shipment to pick up.
{{/if}}`}
            </Snippet>
            <h5>Pin code</h5>
            <Snippet>
              {`{{#if PINCODE}}
  Use {{PINCODE}} when asked for a PIN to collect your parcel.
{{/if}}`}
            </Snippet>
            <h5>Locations</h5>
            <Snippet>
              {`{{#each LOCATIONS}}
  #### At {{NAME}} you have received:

  {{#if LETTERS}}
      You have the following letters to pick up here:
          {{#each LETTERS}}
              - {{CONSIGNMENTNUMBER}}{{#if ISASSIGNEE}}. For: {{ORIGINALRECEIVER.FIRSTNAME}} {{ORIGINALRECEIVER.LASTNAME}}{{/if}}
          {{/each}}
  {{/if}}

  {{#if PARCELS}}
      You have the following parcels to pick up here:
          {{#each PARCELS}}
              - {{CONSIGNMENTNUMBER}}{{#if ISASSIGNEE}}. For: {{ORIGINALRECEIVER.FIRSTNAME}} {{ORIGINALRECEIVER.LASTNAME}}{{/if}}
          {{/each}}
  {{/if}}
{{/each}}`}
            </Snippet>
            <h5>Reminder</h5>
            <Snippet>{'{{#if IsReminder}}This is a reminder of a previos notification{{/if}}'}</Snippet>
          </NoOffsetFlexCol>
        </SnugCol>
      </SnugRow>
    </div>
  );
};
