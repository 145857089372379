import React from 'react';
import ContractContext from '../../contracts/ContractContext';
import { PageHeading, PageHeader, PageContent, PageDescription, StyledLink } from '../../../components/Page';
import Punchcard from '../../../components/statistics/Punchcard';
import StatisticType from '../../../components/statistics/StatisticType';
import { CONTRACT_STATISTICS_QUERY } from '../../Statistics/graph';
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableWrapper,
} from '../../../components/Table';
import { formatDate } from '../../../utils/dateUtil';
import GraphQLProvider from '../../../components/GraphQLProvider';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

const NOTIFICATION_QUEUE_QUERY = gql`
  query ContractNotificationQueue($contractId: Int!) {
    contract(id: $contractId) {
      id
      notificationQueue {
        id
        notificationTime
        inProgress
        retryCount
        consignments {
          id
          consignmentNumber
          lastReminderSent
          receiverUser {
            id
            firstName
            lastName
            email
          }
          assigneeUser {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export default () => (
  <ContractContext>
    {({ contract }) => (
      <React.Fragment>
        <div>
          <GraphQLProvider>
            <PageHeader>
              <PageHeading>Statistics</PageHeading>
            </PageHeader>
            <PageDescription>
              <p>
                This punchcard tells you what's your busiest time of the week. The two types are{' '}
                <StatisticType no={0} /> and <StatisticType no={1} />. A larger circle indicates more activity.
              </p>
              <p>The data presented are all shipments ever registered and delivered.</p>
            </PageDescription>
            <PageContent>
              <Query
                query={CONTRACT_STATISTICS_QUERY}
                skip={!(contract && contract.id)}
                variables={{ contractId: parseInt(contract.id) }}
              >
                {({ data }) => (!data ? null : <Punchcard punchcard={data.contract.statistics.punchcard} />)}
              </Query>
            </PageContent>
            <PageHeader>
              <PageHeading>Notification queue</PageHeading>
            </PageHeader>
            <PageDescription>
              These are the shipments that are queued to be sent to their respective recipient at the time specified in
              the "Send at" column. A high retry count might indicate a problem with the shipment or the email of its
              recipient.
            </PageDescription>
            <PageContent>
              <Query query={NOTIFICATION_QUEUE_QUERY} variables={{ contractId: parseInt(contract.id) }}>
                {({ data }) => (
                  <TableWrapper style={{ overflow: 'hidden' }}>
                    <Table>
                      <TableHeader>
                        <TableHeaderCell>Shipment number</TableHeaderCell>
                        <TableHeaderCell>Send at</TableHeaderCell>
                        <TableHeaderCell>Triggered at</TableHeaderCell>
                        <TableHeaderCell>Processing</TableHeaderCell>
                        <TableHeaderCell>Recipients</TableHeaderCell>
                        <TableHeaderCell>Retry count</TableHeaderCell>
                      </TableHeader>
                      <TableBody>
                        {data &&
                          data.contract &&
                          data.contract.notificationQueue &&
                          data.contract.notificationQueue.map(queueItem =>
                            queueItem.consignments.map(consignment => (
                              <TableRow key={`${queueItem.id}-${consignment.id}`}>
                                <TableCell>
                                  <StyledLink to={`/shipments/${consignment.id}`}>
                                    {consignment.consignmentNumber}
                                  </StyledLink>
                                </TableCell>
                                <TableCell>{formatDate(queueItem.notificationTime)}</TableCell>
                                <TableCell>{formatDate(consignment.lastReminderSent)}</TableCell>
                                <TableCell>{queueItem.inProgress ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                  <div>
                                    Receiver:{' '}
                                    <StyledLink to={`/recipients/edit/${consignment.receiverUser.id}`}>
                                      {consignment.receiverUser.firstName} {consignment.receiverUser.lastName} (
                                      {consignment.receiverUser.email})
                                    </StyledLink>
                                  </div>
                                  {consignment.assigneeUser && (
                                    <div>
                                      Assignee:{' '}
                                      <StyledLink to={`/recipients/edit/${consignment.assigneeUser.id}`}>
                                        {consignment.assigneeUser.firstName} {consignment.assigneeUser.lastName} (
                                        {consignment.assigneeUser.email})
                                      </StyledLink>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>{queueItem.retryCount}</TableCell>
                              </TableRow>
                            ))
                          )}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                )}
              </Query>
            </PageContent>
          </GraphQLProvider>
        </div>
      </React.Fragment>
    )}
  </ContractContext>
);
